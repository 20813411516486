.player {
  display: flex;
  margin-top: -44px;
  padding: 0 0 0;
  overflow: hidden;
}

.player__main {
  width: 100%;
  max-width: 577px;
  /* background-color: #000; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* padding: 0 150px; */
  box-sizing: border-box;
  max-height: calc(100vh - 90px);
  /* height: 100vh; */
}

.player__main-container {
  position: fixed;

}

.player__video {
  max-height: calc(100vh - 90px);
  border: none;

}


.player__product {
  width: calc(100% - 565px);
  height: 160px;
  background-color: #FFF;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  z-index: 100;
  border: 1px solid silver;
}

.player__image-container {
  max-height: 120px;
  max-width: 120px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.player__product-image {
  max-height: 100%;
  max-width: 100%;
}

.player__product-info {
  max-width: calc(100% - 96px - 70px);
}

.player__price {
  font-weight: 500;
  font-size: 18px;
}

.player__title {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.player__cart-button {
  background-color: #ff91c8;
  height: 60px;
  width: 60px;
  border: none;
  border-radius: 35px;
  font-weight: 500;
  margin-left: auto;
  background-image: url("../../../images/icon\ _shopping\ cart.svg");
  background-size: cover;
  background-origin: content-box;
  padding: 18px;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.player__cart-button:hover {
  background-color: #ee88bb;
}

.player__cart-button:active {
  background-color: #d87aa9;
}


.player__video-info {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 40px;
}

.player__user {
  display: flex;
}

.player__author {
  position: absolute;
  color: #FFF;
  right: 150px;
  top: 0;
}

.player__tags {
  position: absolute;
  color: #FFF;
  right: 150px;
  top: 24px;
  display: flex;
  gap: 6px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player__views {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.player__views-icon {
  height: 30px;
  width: 30px;
}

.player__video-button {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 25px;
  background-color: rgb(255, 255, 255, 0.2);
  background-origin: content-box;
  padding: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.player__video-button_close {
  position: absolute;
  left: 20px;
  top: 20px;
  background-image: url('../../../images/close.svg');
}

.player__video-button_comment {
  margin: auto 0 0;
  background-image: url('../../../images/chat_bubble.svg');
  padding: 10px;
}

.player__video-button_share {
  margin: 0 0 50px;
  background-image: url('../../../images/share.svg');
}

.player__arrows {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto 0;
}

.player__video-button_up {
  background-image: url('../../../images/arrow-up.svg');
}

.player__video-button_down {
  background-image: url('../../../images/arrow-down.svg');
}


.player__products {
  /* padding: 50px; */
  /* box-sizing: border-box; */
  /* max-height: calc(100vh - 90px); */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

.player__category {
  margin-top: 50px;
  margin-bottom: 50px;
}

.player__category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player__review-title {
  font-weight: 500;
  font-size: 32px;
}

.player__subtitle {
  font-weight: 500;
  font-size: 24px;
}

.player__gallery {
  margin-top: 20px;
  display: flex;
  gap: 12px;
}

.player__admin {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.player__admin-button {
  background: none;
  border: none;
  text-decoration: underline;
}