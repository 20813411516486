.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c28ea7;
}

.cookie--close {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  border: none;
  background: none;
  svg{
    width: 30px;
    height: 30px;
  }
}

.cookies--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  position: relative;
}

.cookies--container p {
  text-align: center;
}