.catalogue {
  padding: 60px 70px 100px;
}

.catalogue__title {
  font-weight: 500;
  font-size: 32px;
}

.catalogue__products {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.catalogue__category {
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.catalogue__subtitle {
  font-size: 26px;
  font-weight: 500;
  margin-right: auto;
}

.catalogue__more {
  color: #888888;
}

.catalogue__filter-form {
  display: flex;
  flex-wrap: wrap;
  max-height: 24px;
  gap: 30px;
  margin-bottom: 40px;
}

.catalogue__label {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.catalogue__checkbox {
  height: 20px;
  width: 20px;
}

.catalogue__num-input {
  height: 20px;
  width: 70px;
  appearance: textfield;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 0 5px;
}

.catalogue__num-input:focus {
  outline: none;
}

.catalogue__gallery {
  position: relative;
  width: 100%;
}

.catalogue__gallery_scroll {
  display: flex;
  max-width: 100%;
  overflow: hidden;
}

.catalogue__gallery_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;  
}


.catalogue__reviews {
  margin-top: 50px;
}

.catalogue__videos {
  display: flex;
  gap: 20px;
  max-width: 100%;
  position: relative;
}

.catalogue__carousel-btn {
  height: 50px;
  width: 50px;
  background-color: #FFF;
  background-size: cover;
  border: none;
  border-radius: 25px;
  position: absolute;
  top: 40%;
  cursor: pointer;
}

.catalogue__carousel-btn_prev {
  background-image: url('../../../images/arrow-circle-left.svg');
  left: 2px;
}

.catalogue__carousel-btn_next {
  background-image: url('../../../images/arrow-circle-right.svg');
  right: 2px;
}

@import url('./AliceCarousel.css');



@media screen and (max-width: 600px) {
  .catalogue {
    padding: 60px 20px;
  }

  .catalogue__filter-form {
    margin-bottom: 80px;
  }
}